import React from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	DialogTitle,
} from '@material-ui/core';

interface ModalProps {
	open: boolean;
	handleClose: (isOpen: boolean) => void;
	handleApprove: () => void;
}

const ConfirmDeleteModal: React.FC<ModalProps> = (props) => {
	return (
		<div>
			<Dialog open={props.open} onClose={() => props.handleClose(false)}>
				<DialogTitle id="form-dialog-title">Really delete?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please click DELETE to confirm deletion of the selected user(s).
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleApprove} color="primary">
						Delete
					</Button>
					<Button onClick={() => props.handleClose(false)} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ConfirmDeleteModal;
