import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './context/auth-context';

//@ts-ignore
function ProtectedRoute({ component: Component, ...rest }) {
	//@ts-ignore
	const { authenticated } = useAuth();
	return (
		<Route
			{...rest}
			render={(props) => (authenticated ? <Component {...props} /> : <Redirect to="/login" />)}
		/>
	);
}

export default ProtectedRoute;
