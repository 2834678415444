import React, { useState, useEffect } from 'react';
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	Snackbar,
	TextField,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Visiblity from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from '../config/axios';
import { useInput } from '../input/useInput';
import RolesSelector from '../utils/RolesSelector';
import { NewUserInput, RoleInput, User, Role } from '@sixriver/gatekeeper';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

interface AdminCreateUserProps {
	updateUsers: (user: User) => Promise<void>;
	open: boolean;
}

const AdminCreateUser: React.FC<AdminCreateUserProps> = (props) => {
	const classes = useStyles();
	const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
	const { value: password, bind: bindPassword, reset: resetPassword } = useInput('');
	const [showPassword, setshowPassword] = useState(false);
	const [passwordRequired, setPasswordRequired] = useState(true);
	const [error, setError] = useState({ status: false, text: '' });
	const [roles, setRoles] = useState<string[]>([]);
	const [userRoles, setUserRoles] = useState<string[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			await getRoles();
		};
		fetchData();
	}, [props.open]);

	async function getRoles() {
		const rolesResponse = await axiosInstance.get('/cfs/gatekeeper/roles/');
		setRoles(rolesResponse.data.map((role: Role) => role.name));
	}

	function handleRoleAddition(role: string) {
		if (userRoles.includes(role)) {
			setUserRoles((userRoles) => userRoles.filter((matchingRole) => matchingRole !== role));
		} else {
			setUserRoles((userRoles) => [...userRoles, role]);
		}
    // password is not required for user with single chuck_operator role
    setPasswordRequired(userRoles.length === 1 && userRoles[0] === 'chuck_operator');
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const selectedRoles: RoleInput[] = userRoles.map((role) => {
			return { name: role };
		});
		const userData: NewUserInput = {
			email,
			password: password,
			roles: selectedRoles,
			authMethod: null,
		};
		try {
			const userResponse = await axiosInstance.post('/cfs/gatekeeper/users/create/', userData);
			props.updateUsers(userResponse.data);
			resetEmail();
			resetPassword();
		} catch (reqError) {
			console.log(reqError.response);
			setError({
				...error,
				status: true,
				text: reqError.response.data.message,
			});
		}
	};

	const disableButton = (passwordRequired: boolean): boolean => {
		return email === '' || (passwordRequired && password === '') || userRoles.length === 0;
	};

	const handleClickShowPassword = () => {
		setshowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};

	const handleCloseSnackbar = () => {
		setError({ ...error, status: false });
	};

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Grid container direction={'column'} spacing={1}>
				<Grid item xs={12}>
					<FormControl required={true} fullWidth>
						<TextField
							variant="outlined"
							margin="normal"
							required
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							{...bindEmail}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl required={true} fullWidth>
						<TextField
							variant="outlined"
							margin="normal"
							required={passwordRequired}
							id="password"
							type={showPassword ? 'text' : 'password'}
							label="Password"
							name="password"
							autoComplete="current-password"
							{...bindPassword}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{' '}
											{showPassword ? <Visiblity /> : <VisibilityOff />}{' '}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<RolesSelector
						roles={roles}
						userRoles={userRoles}
						handleRoleAddition={handleRoleAddition}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={disableButton(passwordRequired)}
						endIcon={<ExitToAppIcon />}
						type="submit"
						value="Submit"
					>
						Add User
					</Button>
				</Grid>
				{error.status ? (
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						open={true}
						onClose={handleCloseSnackbar}
					>
						<Alert onClose={handleCloseSnackbar} severity="error">
							{error.text}
						</Alert>
					</Snackbar>
				) : null}
			</Grid>
		</form>
	);
};

export default AdminCreateUser;
