import React, { useState, useEffect } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import LoginContainer from './login/LoginContainer';
import AdminContainer from './admin/AdminContainer';
import UserAdminContainer from './userAdmin/UserAdminContainer';
import ChuckUserAdminContainer from './userAdmin/ChuckUserAdminContainer';
import { AuthContext } from './context/auth-context';
import ProtectedRoute from './ProtectedRoute';
import axiosInstance from './config/axios';

const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#757ce8',
			main: '#3f50b5',
			dark: '#002884',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ff7961',
			main: '#f44336',
			dark: '#ba000d',
			contrastText: '#000',
		},
	},
});

const getBasename = (path: any) => path.substr(0, path.lastIndexOf('/'));

const App: React.FC = () => {
	const [authenticated, setAuthenticated] = useState(false);
	const [user, setUser] = useState(undefined);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axiosInstance.get('/cfs/gatekeeper/users/self');
				setUser(res.data);
				setAuthenticated(true);
				setLoading(false);
			} catch (error) {
				setAuthenticated(false);
				setLoading(false);
			}
		};
		fetchData();
	}, []);

	return loading ? null : (
		<ThemeProvider theme={theme}>
			<Router basename={getBasename(window.location.pathname)}>
				<AuthContext.Provider value={{ authenticated, setAuthenticated, user }}>
					<Switch>
						<Route exact path="/login" component={LoginContainer} />
						<ProtectedRoute exact path="/admin" component={AdminContainer} />
						<Route path="/users/:username" component={UserAdminContainer} />
						<Route path="/chuckUsers/:barcode" component={ChuckUserAdminContainer} />
						<Route path="/" component={LoginContainer} />
					</Switch>
				</AuthContext.Provider>
			</Router>
		</ThemeProvider>
	);
};

export default App;
