import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import axiosInstance from '../config/axios';
import ConfirmDeleteModal from '../utils/ConfirmDeleteModal';
import { User, Role, UpdateUserInput } from '@sixriver/gatekeeper';
import AdminNavBar from '../admin/AdminNavBar';
import UserDetails from './UserDetails';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		paper: {
			padding: theme.spacing(2),
			textAlign: 'left',
			color: theme.palette.text.secondary,
		},
		button: {
			margin: theme.spacing(1),
		},
		removeButton: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.error.light,
		},
	}),
);

const UserAdminContainer: React.FC = () => {
	const classes = useStyles();
	const { username } = useParams<any>();
	const [user, setUser] = useState<User>();
	const [userRoles, setUserRoles] = useState<string[]>([]);
	const [readonlyFields] = useState(true);
	const [error, setError] = useState({ status: false, text: '' });
	const [success, setSuccess] = useState<boolean>(false);
	const [redirect, setRedirect] = useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	useEffect(() => {
		const getUser = async (): Promise<void> => {
			const userResponse = await axiosInstance.get(`/cfs/gatekeeper/users/${username}`);
			const fetchedUser: User = userResponse.data;
			setUser(fetchedUser);
			const roles = fetchedUser.roles.map((role) => role.name);
			setUserRoles(roles);
		};
		getUser();
	}, [username, success]);

	function handleRoleAddition(role: string) {
		if (userRoles.includes(role)) {
			setUserRoles((userRoles) => userRoles.filter((matchingRole) => matchingRole !== role));
		} else {
			setUserRoles((userRoles) => [...userRoles, role]);
		}
	}

	const handleSaveClick = async () => {
		const newRoles: Role[] = userRoles.map((role) => {
			return { name: role };
		});
		try {
			if (user) {
				const payload: UpdateUserInput = {
					email: user.email,
					roles: newRoles,
				};
				await axiosInstance.put('/cfs/gatekeeper/users/update', payload);
				setSuccess(true);
			} else {
				throw new Error('User missing params');
			}
		} catch (error) {
			setError({ status: true, text: error.message });
		}
	};

	function handleCloseSnackbar() {
		setError({ ...error, status: false });
		setSuccess(false);
	}

	async function handleApproveDelete(): Promise<void> {
		try {
			if (user) {
				await axiosInstance.delete(`/cfs/gatekeeper/users/${user.email}/delete`);
				setModalOpen(false);
				setRedirect(true);
			} else {
				throw new Error('No user to delete!');
			}
		} catch (error) {
			setError({ status: true, text: 'Unable to delete user' });
		}
	}

	function handleToggleModal(isOpen: boolean) {
		setModalOpen(isOpen);
	}

	return redirect ? (
		<Redirect to="/admin" />
	) : (
		<div className={classes.root}>
			<AdminNavBar />
			<Grid container spacing={3}>
				<UserDetails
					user={user}
					userRoles={userRoles}
					readonlyFields={readonlyFields}
					handleSaveClick={handleSaveClick}
					handleRoleAddition={handleRoleAddition}
					handleDelete={handleToggleModal}
				/>
			</Grid>
			<ConfirmDeleteModal
				open={modalOpen}
				handleClose={handleToggleModal}
				handleApprove={handleApproveDelete}
			/>
			{error.status ? (
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={true}
					onClose={handleCloseSnackbar}
				>
					<Alert onClose={handleCloseSnackbar} severity="error">
						{error.text}
					</Alert>
				</Snackbar>
			) : null}
			{success ? (
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={true}
					onClose={handleCloseSnackbar}
				>
					<Alert onClose={handleCloseSnackbar} severity="success">
						User updated!
					</Alert>
				</Snackbar>
			) : null}
		</div>
	);
};

export default UserAdminContainer;
