import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, IconButton, InputAdornment, Snackbar, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Visiblity from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { useInput } from '../input/useInput';
import { useAuth } from '../context/auth-context';
import axiosInstance from '../config/axios';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const LoginForm: React.FC = () => {
	const classes = useStyles();
	let location = useLocation();
	let history = useHistory();
	//@ts-ignore
	const { setAuthenticated } = useAuth();
	const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
	const { value: password, bind: bindPassword, reset: resetPassword } = useInput('');
	const [showPassword, setshowPassword] = useState(false);
	const [error, setError] = useState({ status: false, text: '' });

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		try {
			await axiosInstance.post('/cfs/gatekeeper/auth/login/', { email, password });
			resetEmail();
			resetPassword();
			if (location.search !== '') {
				let redirectLoc = new URLSearchParams(location.search);
				let redirectTo = redirectLoc.get('url') || window.location.origin;
				let matchOurURL = new RegExp('https://(.+).6river.(org|tech).*');
				if (matchOurURL.test(redirectTo)) {
					window.location.href = redirectTo;
				} else {
					window.location.href = window.location.origin;
				}
			} else {
				setAuthenticated(true);
				history.push('/admin');
			}
		} catch (reqError) {
			console.log(reqError);
			setError({
				...error,
				status: true,
				text: 'Unable to log in.',
			});
		}
	};

	const handleClickShowPassword = () => {
		setshowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};

	const handleCloseSnackbar = () => {
		setError({ ...error, status: false });
	};

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				id="email"
				label="Username"
				name="email"
				autoComplete="email"
				autoFocus
				// className={errors.email && touched.email && 'error'}
				{...bindEmail}
			/>
			<TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				id="password"
				type={showPassword ? 'text' : 'password'}
				label="Password"
				name="password"
				autoComplete="current-password"
				{...bindPassword}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{' '}
								{showPassword ? <Visiblity /> : <VisibilityOff />}{' '}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			<Button
				variant="contained"
				color="primary"
				className={classes.submit}
				endIcon={<ExitToAppIcon />}
				type="submit"
				value="Submit"
			>
				Sign In
			</Button>
			{error.status ? (
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={true}
					onClose={handleCloseSnackbar}
				>
					<Alert onClose={handleCloseSnackbar} severity="error">
						{error.text}
					</Alert>
				</Snackbar>
			) : null}
		</form>
	);
};

export default LoginForm;
