import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(1),
		},
	}),
);

interface RolesSelectorProps {
	userRoles: string[];
	roles: string[];
	handleRoleAddition: (role: string) => void;
}

const RolesSelector: React.FC<RolesSelectorProps> = (props) => {
	const classes = useStyles();

	const halfRoles = props.roles.slice(0, props.roles.length / 2);
	const otherHalfRoles = props.roles.slice(props.roles.length / 2, props.roles.length);

	return (
		<FormControl
			required
			error={props.userRoles.length === 0}
			component="fieldset"
			className={classes.formControl}
		>
			<FormLabel component="legend">Roles</FormLabel>
			<FormGroup row>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						{halfRoles.map((role) => {
							return (
								<Grid item xs={12} key={role}>
									<FormControlLabel
										control={
											<Checkbox
												checked={props.userRoles.includes(role)}
												onChange={() => {
													props.handleRoleAddition(role);
												}}
												name={role}
											/>
										}
										label={role}
										key={role}
									/>
								</Grid>
							);
						})}
					</Grid>
					<Grid item xs={6}>
						{otherHalfRoles.map((role) => {
							return (
								<Grid item xs={12} key={role}>
									<FormControlLabel
										control={
											<Checkbox
												checked={props.userRoles.includes(role)}
												onChange={() => {
													props.handleRoleAddition(role);
												}}
												name={role}
											/>
										}
										label={role}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</FormGroup>
			<FormHelperText>Select at least one role</FormHelperText>
		</FormControl>
	);
};

export default RolesSelector;
