import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Paper, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import axiosInstance from '../config/axios';
import axios from 'axios';
import { User, ChuckUser, BulkChuckUserInput } from '@sixriver/gatekeeper';
import AdminUserTable from './AdminUserTable';
import AdminChuckUserTable from './AdminChuckUserTable';
import AdminNavBar from './AdminNavBar';
import AdminDrawer from './AdminDrawer';
import ConfirmDeleteModal from '../utils/ConfirmDeleteModal';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		paper: {
			padding: theme.spacing(2),
			textAlign: 'left',
			color: theme.palette.text.secondary,
		},
		button: {
			margin: theme.spacing(1),
		},
		removeButton: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.error.light,
		},
	}),
);

const AdminContainer: React.FC = () => {
	const classes = useStyles();
	const [sidebar, toggleSidebar] = useState<boolean>(false);
	const [addUserForm, setAddUserForm] = useState<boolean>(false);
	const [users, setUsers] = useState<User[]>([]);
	const [chuckUsers, setChuckUsers] = useState<ChuckUser[]>([]);
	const [selected, setSelected] = useState<User[]>([]);
	const [selectedChuckUsers, setSelectedChuckUsers] = useState<ChuckUser[]>([]);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [error, setError] = useState({ status: false, text: '' });

	const openOrCloseSidebar = (open: boolean) => (event: any) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		toggleSidebar(!open);
	};

	// Get users from database, returns array of JSON
	useEffect(() => {
		const fetchData = async () => {
			await getUsers();
		};
		fetchData();
	}, []);

	async function getUsers(): Promise<void> {
		const fetchedUsers = await axiosInstance.get('/cfs/gatekeeper/users/');
		const fetchedChuckUsers = await axiosInstance.get('/cfs/gatekeeper/chuckUsers/all');
		const filteredUsers = fetchedUsers.data.map((user: User) => {
			if (user.authMethod === 'LDAP' || user.email.includes('@6river')) {
				user.authMethod = '6 River Systems';
			} else {
				user.authMethod = 'Internal';
			}
			user.roles = user.roles.map((role: any) => role.name);
			return user;
		});
		setUsers([...filteredUsers]);
		setChuckUsers([...fetchedChuckUsers.data]);
	}

	async function addUser(user: User): Promise<void> {
		await getUsers();
	}

	function deleteUsers() {
		if (selected.length === 0) {
			setError({ status: true, text: 'Please select at least one user' });
		} else {
			setError({ status: false, text: '' });
			handleToggleModal(true);
		}
	}

	function deleteChuckUsers() {
		if (selectedChuckUsers.length === 0) {
			setError({ status: true, text: 'Please select at least one chuckUser' });
		} else {
			setError({ status: false, text: '' });
			handleToggleModal(true);
		}
	}

	function handleToggleModal(isOpen: boolean) {
		setModalOpen(isOpen);
	}

	async function handleApproveDelete(): Promise<void> {
		const requests: any[] = [];
		selected.forEach((user) => {
			requests.push(axiosInstance.delete(`/cfs/gatekeeper/users/${user.email}/delete`));
		});
		const chuckUsersBody: BulkChuckUserInput = {
			chuckUsers: [],
		};
		selectedChuckUsers.forEach((user) => {
			chuckUsersBody.chuckUsers.push({
				badgeBarcode: user.badgeBarcode,
				active: false,
			});
		});
		requests.push(axiosInstance.put(`/cfs/gatekeeper/chuckUsers/updateMany`, chuckUsersBody));
		setModalOpen(false);
		await axios.all(requests);
		await getUsers();
	}

	function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.target.checked) {
			setSelected(users);
			return;
		}
		setSelected([]);
	}

	function handleSelectAllChuckUsersClick(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.target.checked) {
			setSelectedChuckUsers(chuckUsers);
			return;
		}
		setSelectedChuckUsers([]);
	}

	function handleSelectClick(user: User) {
		let currentSelection = [...selected];
		if (selected.includes(user)) {
			const newSelection = currentSelection.filter((unSelectedUser) => {
				return unSelectedUser.email !== user.email;
			});
			setSelected(newSelection);
			return;
		} else {
			currentSelection.push(user);
			setSelected(currentSelection);
			return;
		}
	}

	function handleSelectChuckUserClick(user: ChuckUser) {
		let currentSelection = [...selectedChuckUsers];
		if (selectedChuckUsers.includes(user)) {
			const newSelection = currentSelection.filter((unSelectedUser) => {
				return unSelectedUser.badgeBarcode !== user.badgeBarcode;
			});
			setSelectedChuckUsers(newSelection);
			return;
		} else {
			currentSelection.push(user);
			setSelectedChuckUsers(currentSelection);
			return;
		}
	}

	function handleCloseSnackbar() {
		setError({ ...error, status: false });
	}

	return (
		<div className={classes.root}>
			<AdminNavBar />
			<Grid container spacing={3}>
				<Grid item xs={8}>
					<Paper className={classes.paper}>
						<Button
							variant="contained"
							className={classes.button}
							color="primary"
							onClick={() => {
								setAddUserForm(true);
								toggleSidebar(!sidebar);
							}}
						>
							Add User
						</Button>
						<Button variant="contained" className={classes.removeButton} onClick={deleteUsers}>
							Remove User
						</Button>
						<AdminUserTable
							users={users}
							rowCount={users.length}
							numSelected={selected.length}
							onSelectAllClick={handleSelectAllClick}
							onSelectClick={handleSelectClick}
							selectedUsers={selected.map((user) => user.email)}
						/>
					</Paper>
					<Paper className={classes.paper}>
						<Button
							variant="contained"
							className={classes.button}
							color="primary"
							onClick={() => {
								setAddUserForm(false);
								toggleSidebar(!sidebar);
							}}
						>
							Add Chuck/Packout User
						</Button>
						<Button variant="contained" className={classes.removeButton} onClick={deleteChuckUsers}>
							Deactivate Chuck/Packout User
						</Button>
						<AdminChuckUserTable
							users={chuckUsers}
							rowCount={users.length}
							numSelected={selected.length}
							onSelectAllClick={handleSelectAllChuckUsersClick}
							onSelectClick={handleSelectChuckUserClick}
							selectedChuckUsers={selectedChuckUsers.map((user) => user.badgeBarcode)}
						/>
					</Paper>
					<ConfirmDeleteModal
						open={modalOpen}
						handleClose={handleToggleModal}
						handleApprove={handleApproveDelete}
					/>
				</Grid>
				<AdminDrawer
					addUserForm={addUserForm}
					open={sidebar}
					location="right"
					onClose={openOrCloseSidebar}
					updateUsers={addUser}
				/>
				{error.status ? (
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						open={true}
						onClose={handleCloseSnackbar}
					>
						<Alert onClose={handleCloseSnackbar} severity="error">
							{error.text}
						</Alert>
					</Snackbar>
				) : null}
			</Grid>
		</div>
	);
};

export default AdminContainer;
