import React from 'react';
import { Avatar, Container, CssBaseline, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoginForm from './LoginForm';
import logo from './sixrslogo.png';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: 'none',
		outlineColor: theme.palette.primary.light,
		width: theme.spacing(10),
		height: theme.spacing(10),
	},
}));

const LoginContainer: React.FC = () => {
	const classes = useStyles();

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar} src={logo} alt="6RS logo" />
				<Typography component="h1" variant="h5">
					Sign In
				</Typography>
				<LoginForm />
			</div>
		</Container>
	);
};

export default LoginContainer;
