import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ChuckUser } from '@sixriver/gatekeeper/dist/src/users/chuckUser.model';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
	head: {
		backgroundColor: theme.palette.primary.light,
	},
}));

interface AdminChuckUserTableProps {
	users: ChuckUser[];
	numSelected: number;
	rowCount: number;
	selectedChuckUsers: string[];
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
	onSelectClick: (user: ChuckUser) => void;
}

const AdminChuckUserTable: React.FC<AdminChuckUserTableProps> = (props) => {
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size="small" aria-label="chuck users table">
				<TableHead className={classes.head}>
					<TableRow>
						<TableCell padding="checkbox">
							<Checkbox
								indeterminate={props.numSelected > 0 && props.numSelected < props.rowCount}
								checked={props.rowCount > 0 && props.numSelected === props.rowCount}
								onChange={props.onSelectAllClick}
								inputProps={{ 'aria-label': 'select all chuck users' }}
							/>
						</TableCell>
						<TableCell align="left" style={{ fontWeight: 'bold' }}>
							Badge Barcode
						</TableCell>
						<TableCell align="left" style={{ fontWeight: 'bold' }}>
							ID
						</TableCell>
						<TableCell align="left" style={{ fontWeight: 'bold' }}>
							User Name
						</TableCell>
						<TableCell align="left" style={{ fontWeight: 'bold' }}>
							Last Login Time
						</TableCell>
						<TableCell align="left" style={{ fontWeight: 'bold' }}>
							Active?
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.users.map((user, index) => (
						<TableRow key={user.badgeBarcode} hover role="checkbox">
							<TableCell padding="checkbox">
								<Checkbox
									checked={props.selectedChuckUsers.indexOf(user.badgeBarcode) !== -1}
									onChange={() => props.onSelectClick(user)}
									inputProps={{ 'aria-labelledby': `chuck-users-table-checkbox-${index}` }}
								/>
							</TableCell>
							<TableCell component="th" scope="row">
								<Link to={'/chuckUsers/' + user.id}>{user.badgeBarcode}</Link>
							</TableCell>
							<TableCell component="th" scope="row">
								{user.id}
							</TableCell>
							<TableCell component="th" scope="row">
								{user.name}
							</TableCell>
							<TableCell component="th" scope="row">
								{user.lastLogin}
							</TableCell>
							<TableCell component="th" scope="row">
								{user.deletedAt ? 'Inactive' : 'Active'}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default AdminChuckUserTable;
