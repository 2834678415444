import React, { useState } from 'react';
import { Button, FormControl, Grid, Snackbar, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from '../config/axios';
import { useInput } from '../input/useInput';
import { ChuckUserInput, User } from '@sixriver/gatekeeper';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

interface AdminCreateChuckUserProps {
	updateUsers: (user: User) => Promise<void>;
	open: boolean;
}

const AdminCreateChuckUser: React.FC<AdminCreateChuckUserProps> = (props) => {
	const classes = useStyles();
	const { value: barcode, bind: bindBarcode, reset: resetBarcode } = useInput('');
	const { value: name, bind: bindName, reset: resetName } = useInput('');
	const [error, setError] = useState({ status: false, text: '' });

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const userData: ChuckUserInput = {
			badgeBarcode: barcode,
			name,
		};
		try {
			const userResponse = await axiosInstance.post('/cfs/gatekeeper/chuckUsers/create/', userData);
			props.updateUsers(userResponse.data);
			resetBarcode();
			resetName();
		} catch (reqError) {
			console.log(reqError.response);
			setError({
				...error,
				status: true,
				text: reqError.response.data.message,
			});
		}
	};

	const disableButton = (): boolean => {
		return barcode === '';
	};

	const handleCloseSnackbar = () => {
		setError({ ...error, status: false });
	};

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Grid item xs={12}>
				<Grid item xs={12}>
					<FormControl required={true} fullWidth>
						<TextField
							variant="outlined"
							margin="normal"
							required
							id="badge_barcode"
							label="Barcode"
							name="badge_barcode"
							autoComplete="badge_barcode"
							{...bindBarcode}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<TextField
							variant="outlined"
							margin="normal"
							id="name"
							label="Name (optional)"
							name="name"
							autoComplete="name"
							{...bindName}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<Button
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={disableButton()}
						endIcon={<ExitToAppIcon />}
						type="submit"
						value="Submit"
					>
						Add User
					</Button>
				</Grid>
				{error.status ? (
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						open={true}
						onClose={handleCloseSnackbar}
					>
						<Alert onClose={handleCloseSnackbar} severity="error">
							{error.text}
						</Alert>
					</Snackbar>
				) : null}
			</Grid>
		</form>
	);
};

export default AdminCreateChuckUser;
