import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { User } from '@sixriver/gatekeeper';
import AdminCreateUser from './AdminCreateUser';
import AdminCreateChuckUser from './AdminCreateChuckUser';

const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		padding: theme.spacing(2),
		textAlign: 'left',
		color: theme.palette.text.secondary,
	},
}));

interface AdminDrawerProps {
	addUserForm: boolean;
	open: boolean;
	location: 'left' | 'top' | 'right' | 'bottom';
	onClose(open: boolean): any;
	updateUsers: (user: User) => Promise<void>;
}

const AdminDrawer: React.FC<AdminDrawerProps> = (props) => {
	const classes = useStyles();

	return props.addUserForm ? (
		<Drawer
			anchor={props.location}
			open={props.open}
			onClose={props.onClose(props.open)}
			className={classes.drawer}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			{' '}
			<AdminCreateUser updateUsers={props.updateUsers} open={props.open} />
		</Drawer>
	) : (
		<Drawer
			anchor={props.location}
			open={props.open}
			onClose={props.onClose(props.open)}
			className={classes.drawer}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			{' '}
			<AdminCreateChuckUser updateUsers={props.updateUsers} open={props.open} />
		</Drawer>
	);
};

export default AdminDrawer;
