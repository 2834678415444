import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core';
import { ChuckUser } from '@sixriver/gatekeeper/dist/src/users/chuckUser.model';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		card: {
			padding: theme.spacing(2),
			'& .MuiTextField-root': {
				margin: theme.spacing(1),
				width: 500,
			},
			minWidth: '900',
		},
		paper: {
			textAlign: 'left',
			color: theme.palette.text.secondary,
		},
		bullet: {
			display: 'inline-block',
			margin: '0 2px',
			transform: 'scale(0.8)',
		},
		title: {
			fontSize: 18,
			fontWeight: 'bold',
		},
		pos: {
			marginBottom: 12,
		},
		button: {
			margin: theme.spacing(1),
		},
		removeButton: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.error.light,
		},
		formControl: {
			margin: theme.spacing(1),
		},
	}),
);

interface ChuckUserDetailsProps {
	user: ChuckUser | undefined;
	readonlyFields: boolean;
	handleSaveClick: () => Promise<void>;
	handleDelete: (toggle: boolean) => void;
}

const ChuckUserDetails: React.FC<ChuckUserDetailsProps> = (props) => {
	const classes = useStyles();

	if (typeof props.user === 'undefined' && !props.user) {
		return (
			<div className={classes.root}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<Card className={classes.root} variant="outlined">
							<CardContent>
								<Typography
									className={classes.title}
									color="textSecondary"
									gutterBottom
								></Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		);
	} else {
		return (
			<div className={classes.root}>
				<Grid container spacing={3}>
					<Grid item xs={8}>
						<Card className={classes.card} variant="outlined">
							<CardContent>
								<Typography className={classes.title} gutterBottom>
									User Details
								</Typography>
								<TextField
									id="outlined-read-only-input"
									label="Name"
									defaultValue={props.user.name}
									InputProps={{
										readOnly: props.readonlyFields,
									}}
									variant="outlined"
								/>
								<TextField
									id="outlined-read-only-input"
									label="Badge"
									defaultValue={props.user.badgeBarcode}
									InputProps={{
										readOnly: props.readonlyFields,
									}}
									variant="outlined"
								/>
							</CardContent>
							<CardContent>
								<Typography className={classes.title} gutterBottom>
									User Status
								</Typography>
								<TextField
									id="outlined-read-only-input"
									label="Created"
									defaultValue={props.user.createdAt}
									InputProps={{
										readOnly: true,
									}}
									variant="outlined"
								/>
								<TextField
									id="outlined-read-only-input"
									label="Last updated"
									defaultValue={props.user.updatedAt}
									InputProps={{
										readOnly: true,
									}}
									variant="outlined"
								/>
							</CardContent>
							<CardActions>
								<Button
									variant="contained"
									className={classes.removeButton}
									onClick={() => {
										props.handleDelete(true);
									}}
								>
									Deactivate User
								</Button>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</div>
		);
	}
};

export default ChuckUserDetails;
