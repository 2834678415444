import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import axiosInstance from '../config/axios';
import ConfirmDeleteModal from '../utils/ConfirmDeleteModal';
import AdminNavBar from '../admin/AdminNavBar';
import ChuckUserDetails from './ChuckUserDetails';
import { ChuckUserInput } from '@sixriver/gatekeeper';
import { ChuckUser } from '@sixriver/gatekeeper/dist/src/users/chuckUser.model';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		paper: {
			padding: theme.spacing(2),
			textAlign: 'left',
			color: theme.palette.text.secondary,
		},
		button: {
			margin: theme.spacing(1),
		},
		removeButton: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.error.light,
		},
	}),
);

const ChuckUserAdminContainer: React.FC = () => {
	const classes = useStyles();
	const { barcode } = useParams<any>();
	const [user, setUser] = useState<ChuckUser>();
	const [readonlyFields] = useState(true);
	const [error, setError] = useState({ status: false, text: '' });
	const [success, setSuccess] = useState<boolean>(false);
	const [redirect, setRedirect] = useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	useEffect(() => {
		const getUser = async (): Promise<void> => {
			const userResponse = await axiosInstance.get(`/cfs/gatekeeper/chuckUsers/${barcode}`);
			const fetchedUser: ChuckUser = userResponse.data;
			setUser(fetchedUser);
		};
		getUser();
	}, [barcode, success]);

	const handleSaveClick = async () => {
		try {
			if (user) {
				const payload: ChuckUserInput = { badgeBarcode: user.badgeBarcode };
				await axiosInstance.put('/cfs/gatekeeper/chuckUsers/update', payload);
				setSuccess(true);
			} else {
				throw new Error('User missing params');
			}
		} catch (error) {
			setError({ status: true, text: error.message });
		}
	};

	function handleCloseSnackbar() {
		setError({ ...error, status: false });
		setSuccess(false);
	}

	async function handleApproveDelete(): Promise<void> {
		try {
			if (user) {
				await axiosInstance.put(`/cfs/gatekeeper/chuckUsers/update`, {
					badgeBarcode: user.badgeBarcode,
					active: false,
				});
				setModalOpen(false);
				setRedirect(true);
			} else {
				throw new Error('User missing params');
			}
		} catch (error) {
			setError({ status: true, text: 'Unable to deactivate user' });
		}
	}

	function handleToggleModal(isOpen: boolean) {
		setModalOpen(isOpen);
	}

	return redirect ? (
		<Redirect to="/admin" />
	) : (
		<div className={classes.root}>
			<AdminNavBar />
			<Grid container spacing={3}>
				<ChuckUserDetails
					user={user}
					readonlyFields={readonlyFields}
					handleSaveClick={handleSaveClick}
					handleDelete={handleToggleModal}
				/>
			</Grid>
			<ConfirmDeleteModal
				open={modalOpen}
				handleClose={handleToggleModal}
				handleApprove={handleApproveDelete}
			/>
			{error.status ? (
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={true}
					onClose={handleCloseSnackbar}
				>
					<Alert onClose={handleCloseSnackbar} severity="error">
						{error.text}
					</Alert>
				</Snackbar>
			) : null}
			{success ? (
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={true}
					onClose={handleCloseSnackbar}
				>
					<Alert onClose={handleCloseSnackbar} severity="success">
						User updated!
					</Alert>
				</Snackbar>
			) : null}
		</div>
	);
};

export default ChuckUserAdminContainer;
