import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from '../config/axios';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		display: 'flex',
	},
	menuButton: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.contrastText,
	},
	title: {
		flexGrow: 1,
	},
}));

const AdminNavBar: React.FC = () => {
	const classes = useStyles();
	const [loggedOut, setLoggedOut] = useState<boolean>(false);

	async function handleLogoutClick() {
		try {
			const res = await axiosInstance.post('/cfs/gatekeeper/auth/logout/');
			if (res.status === 200) {
				setLoggedOut(true);
			} else {
				throw new Error(res.statusText);
			}
		} catch (error) {
			console.log(error);
		}
	}

	return loggedOut ? (
		<Redirect to="/login" />
	) : (
		<div className={classes.root}>
			<AppBar position="sticky">
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						<Button component={Link} to="/admin" color="inherit">
							Gatekeeper Administration
						</Button>
					</Typography>
					<Button variant="contained" href={window.location.origin} className={classes.menuButton}>
						Management Console
					</Button>
					<Button
						variant="contained"
						startIcon={<ExitToAppIcon />}
						className={classes.menuButton}
						onClick={handleLogoutClick}
					>
						Logout
					</Button>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default AdminNavBar;
