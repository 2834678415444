import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core';
import RolesSelector from '../utils/RolesSelector';
import axiosInstance from '../config/axios';
import { User, Role } from '@sixriver/gatekeeper';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		card: {
			padding: theme.spacing(2),
			'& .MuiTextField-root': {
				margin: theme.spacing(1),
				width: 500,
			},
			minWidth: '900',
		},
		paper: {
			textAlign: 'left',
			color: theme.palette.text.secondary,
		},
		bullet: {
			display: 'inline-block',
			margin: '0 2px',
			transform: 'scale(0.8)',
		},
		title: {
			fontSize: 18,
			fontWeight: 'bold',
		},
		pos: {
			marginBottom: 12,
		},
		button: {
			margin: theme.spacing(1),
		},
		removeButton: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.error.light,
		},
		formControl: {
			margin: theme.spacing(1),
		},
	}),
);

interface UserDetailsProps {
	user: User | undefined;
	userRoles: string[];
	readonlyFields: boolean;
	handleSaveClick: () => Promise<void>;
	handleRoleAddition: (role: string) => void;
	handleDelete: (toggle: boolean) => void;
}

const UserDetails: React.FC<UserDetailsProps> = (props) => {
	const classes = useStyles();
	const [roles, setRoles] = useState<string[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			await getRoles();
		};
		fetchData();
	}, [props.user]);

	async function getRoles() {
		const rolesResponse = await axiosInstance.get('/cfs/gatekeeper/roles/');
		setRoles(rolesResponse.data.map((role: Role) => role.name));
	}

	function roleComparison(newUserRoles: string[], originalUserRoles: Role[]): boolean {
		const originalRoles = originalUserRoles.map((role) => role.name);
		const diffedRoles = newUserRoles.filter((role) => !originalRoles.includes(role));
		if (originalRoles.length !== newUserRoles.length && newUserRoles.length > 0) {
			// if the new set of roles is longer than the original set, and isn't 0
			return false;
		} else if (diffedRoles.length > 0) {
			// if the length is the same but the role has changed
			return false;
		} else {
			// Never save a user without any roles
			return true;
		}
	}

	if (typeof props.user === 'undefined' && !props.user) {
		return (
			<div className={classes.root}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<Card className={classes.root} variant="outlined">
							<CardContent>
								<Typography
									className={classes.title}
									color="textSecondary"
									gutterBottom
								></Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		);
	} else {
		return (
			<div className={classes.root}>
				<Grid container spacing={3}>
					<Grid item xs={8}>
						<Card className={classes.card} variant="outlined">
							<CardContent>
								<Typography className={classes.title} gutterBottom>
									User Details
								</Typography>
								<TextField
									id="outlined-read-only-input"
									label="Email"
									defaultValue={props.user.email}
									InputProps={{
										readOnly: props.readonlyFields,
									}}
									variant="outlined"
								/>
								<RolesSelector
									handleRoleAddition={props.handleRoleAddition}
									userRoles={props.userRoles}
									roles={roles}
								/>
							</CardContent>
							<CardContent>
								<Typography className={classes.title} gutterBottom>
									User Status
								</Typography>
								<TextField
									id="outlined-read-only-input"
									label="Created"
									defaultValue={props.user.createdAt}
									InputProps={{
										readOnly: true,
									}}
									variant="outlined"
								/>
								<TextField
									id="outlined-read-only-input"
									label="Last updated"
									defaultValue={props.user.updatedAt}
									InputProps={{
										readOnly: true,
									}}
									variant="outlined"
								/>
							</CardContent>
							<CardActions>
								<Button
									variant="contained"
									className={classes.button}
									color="primary"
									onClick={props.handleSaveClick}
									disabled={roleComparison(props.userRoles, props.user.roles)}
								>
									Save User
								</Button>
								{/* TODO: Set up Password Reset */}
								<Button
									variant="contained"
									className={classes.removeButton}
									onClick={() => {
										props.handleDelete(true);
									}}
								>
									Remove User
								</Button>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</div>
		);
	}
};

export default UserDetails;
